import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Chart } from 'react-google-charts';
import { useQuery } from 'react-query';
import { useParams } from '@reach/router';
import { useRecoilState, useRecoilValue } from 'recoil';

// Components
import DateTimePicker from 'ui/DateTimePicker';
import { PrimaryButton } from 'ui/Button';
import {
  DropdownMetricGroup,
  DropdownMetricOrder,
  DropdownSelect,
  DropdownCustomisaton,
  DropdownChartNames
  // SimpleInput
} from 'components/FormHelpers';
import { BackIcon } from 'svgs';
// Api
import { registerCustomMetric, getCustomMetric } from 'ajax/dashboard';
import {
  getDefaultMetrics,
  getDefaultMetricsWithoutCustomisation,
  getDynamicCharts
} from 'ajax/dashboard/helper';

// Utils
import { getDateNumberOfDaysBehind } from 'utils/getDateNumberOfDaysBehind';
import {
  getMonthStartEndDates,
  getLastYearDatesIso,
  getLastMonthDatesIso,
  getYearStartEndDates
} from 'utils/dateRangeSelector';
import { toast } from 'react-toastify';
import {
  profileState,
  dashboardFirstRun,
  dashboardMetric,
  dashboardChartData,
  dashboardRawData,
  dashboardCustomisation,
  dashboardDefaultData,
  dashboardRange,
  dashboardDate,
  dashboardAscending,
  dashboardLimit,
  dashboardGroup,
  dashboardOrder
} from 'state';
import { useDidMountEffect } from 'hooks';

const options = {
  title: 'Default Title',
  width: '100%',
  responsive: true,
  height: 'calc(100vh - 31rem)',
  legend: { position: 'bottom' },
  //   legend: 'none',
  tooltip: { isHtml: true, trigger: 'visible' },
  chartArea: { width: '70%', height: '70%' }
  // hAxis: {
  //   format: '#,##'
  // }
  //   vAxis: {
  //     title: 'City'
  //   }
};

// const tableColumn = ['User', 'Spending', { role: 'tooltip', p: { html: true } }];

const getDefaultValues = async ({ customisation, name }) => {
  const payload = {
    customisation,
    name
  };

  let url = getDefaultMetrics;

  if (name && !customisation) {
    delete payload.customisation;
    url = getDefaultMetricsWithoutCustomisation;
  }

  if (!name && !customisation) {
    url = getDynamicCharts;
  }

  const request = await url(payload);

  if (request.status !== 200) {
    console.error('Error getting default values');
    return navigate('/dashboard/');
  }
  return request.data;
};

const registerMetric = async ({ name, date, group, order, ascending, limit, customisation, field }) => {
  const payload = {
    name,
    customisation,
    field,
    from: date.from,
    to: date.to,
    group,
    order,
    ascending: ascending === 'Yes',
    limit: parseInt(limit, 10)
  };
  const request = await registerCustomMetric(payload);
  if (request.status !== 200) {
    toast.error('Error registering metric');
  }
  return request.data;
};

const tableColumn = [];

export default function SpendingChart({ dynamicChartProp }) {
  const profile = useRecoilValue(profileState);

  let isDirectURL = false;

  // If no dynamicChartProp are set then it is a direct URL
  if (!dynamicChartProp) {
    isDirectURL = true;
  }

  const dynamicChart = dynamicChartProp || {};

  const params = useParams();

  if (params?.customisation) {
    dynamicChart.customisation = params?.customisation;
  }

  if (params?.name) {
    dynamicChart.name = params?.name;
  }

  if (!dynamicChart.customisation) {
    dynamicChart.customisation = profile.customisation_id;
  }

  const [orderSearch, setOrderSearch] = useState('');
  const [showOrderSearch, setShowOrderSearch] = useState(false);
  const [chartName, setChartName] = useState(params?.name || '');

  const [redirectValue, setRedirectValue] = useState('');
  const [dashboardFirstRunState, setDashboardFirstRunState] = useRecoilState(dashboardFirstRun);
  const [metric, setMetric] = useRecoilState(dashboardMetric);
  const chartModal = !!isDirectURL;
  const [chartData, setChartData] = useRecoilState(dashboardChartData);
  const [rawData, setRawData] = useRecoilState(dashboardRawData);
  const [customisation, setCustomisation] = useRecoilState(
    dashboardCustomisation || params?.customisation || ''
  );

  const [defaultData, setDefaultData] = useRecoilState(dashboardDefaultData);

  const [range, setRange] = useRecoilState(dashboardRange);
  const [date, setDate] = useRecoilState(dashboardDate);
  const [ascending, setAscending] = useRecoilState(dashboardAscending);
  const [limit, setLimit] = useRecoilState(dashboardLimit);
  const [group, setGroup] = useRecoilState(dashboardGroup);
  const [order, setOrder] = useRecoilState(dashboardOrder);

  const { data, isLoading, isRefetching } = useQuery(
    ['spend_metrics', metric?.identity],
    async () => {
      const request = await getCustomMetric(metric.identity);
      if (request.status !== 200) {
        return { chartData: [], tableColumns: [] };
      }

      let tableColumns = [];

      // Step 1: Identify all unique keys
      const allKeys = request.data.data.reduce((keys, item) => {
        Object.keys(item.Values).forEach((key) => {
          if (!keys.includes(key)) {
            keys.push(key);
          }
        });
        return keys;
      }, []);

      const chartType = request.data?.data[0]?.ValueType || '';

      // Step 2: Construct table columns
      tableColumns = ['Name', ...allKeys];

      if (chartType === 'Currencyy') {
        tableColumns.push({ type: 'string', role: 'tooltip', p: { html: true } });
      }

      // Step 3: Prepare chart data
      const chartData = request.data.data.map((item) => {
        // Initialize an array with 0 values for all keys
        const valuesArray = allKeys.map((key) => item.Values[key] || 0);

        if (chartType === 'Currencyy') {
          const keyValuePairs = Object.entries(item.Values)
            .map(
              ([key, value]) =>
                `<div>${key}: <b>£${value.toLocaleString('en-GB', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}</b></div>`
            )
            .join('');
          valuesArray.push(
            `<div class="p-3"><div class="mb-3 text-[13px]"><b>${item.Name}</b></div>${keyValuePairs}</div>`
          );
        }

        return [item.Name, ...valuesArray];
      });

      // Insert table columns as the first element in chartData
      chartData.unshift(tableColumns);

      return {
        chartData,
        tableColumns,
        chartTitle: request.data.name,
        chartType: request.data?.data[0]?.ValueType || '',
        rawData: request?.data?.data || []
      };
    },
    {
      enabled: !!metric,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setChartData(data.chartData);
        setRawData(data.rawData);
      }
    }
  );

  // Set From Date
  const setFromDate = (e) => {
    if (range !== '') {
      setRange('');
    }
    setDate({ ...date, from: e, isFromValid: e !== '' });
  };

  // Set To Date
  const setToDate = (e) => {
    if (range !== '') {
      setRange('');
    }
    setDate({ ...date, to: e, isToValid: e !== '' });
  };

  const searchResetHandler = () => {
    if (!defaultData) return;
    setDate({
      from: defaultData.from,
      to: defaultData.to
    });
    setGroup(defaultData.group);
    setOrder(defaultData.order);
    setAscending(defaultData.ascending ? 'Yes' : 'No');
    setLimit(defaultData.limit.toString());
    setRange('');
  };

  const setDatesRange = (range) => {
    if (range === 'Blank') return;

    if (range === 'Today') {
      setDate({
        from: getDateNumberOfDaysBehind(0, false),
        to: getDateNumberOfDaysBehind(0, true)
      });
      return;
    }

    if (range === 'Yesterday') {
      setDate({
        from: getDateNumberOfDaysBehind(1, false),
        to: getDateNumberOfDaysBehind(1, true)
      });
    }

    if (range === 'Last 7 days') {
      setDate({
        from: getDateNumberOfDaysBehind(7, false),
        to: getDateNumberOfDaysBehind(0, true)
      });
    }

    if (range === 'Last 30 days') {
      setDate({
        from: getDateNumberOfDaysBehind(30, false),
        to: getDateNumberOfDaysBehind(0, true)
      });
    }

    if (range === 'Last 60 days') {
      setDate({
        from: getDateNumberOfDaysBehind(60, false),
        to: getDateNumberOfDaysBehind(0, true)
      });
    }

    if (range === 'Last 90 days') {
      setDate({
        from: getDateNumberOfDaysBehind(90, false),
        to: getDateNumberOfDaysBehind(0, true)
      });
    }

    if (range === 'This month') {
      const monthDates = getMonthStartEndDates();

      setDate({
        from: monthDates.startDate,
        to: monthDates.endDate
      });
    }

    if (range === 'This year') {
      const yearDates = getYearStartEndDates();

      setDate({
        from: yearDates.startDate,
        to: yearDates.endDate
      });
    }

    if (range === 'Last month') {
      const yearDates = getLastMonthDatesIso();

      setDate({
        from: yearDates.startDate,
        to: yearDates.endDate
      });
    }
    if (range === 'Last year') {
      const yearDates = getLastYearDatesIso();

      setDate({
        from: yearDates.startDate,
        to: yearDates.endDate
      });
    }
  };

  // Redirect if params are invalid
  // useEffect(() => {
  //   if (!dynamicChart.customisation) return alert('/dashboard-1');
  //   if (!dynamicChart.name) return alert('/dashboard-2');
  //   return null;
  // }, []);

  // Fetch Default Data
  useEffect(() => {
    if (!dashboardFirstRunState) return;

    async function fetchDefaultValues() {
      const [defResponse] = await getDefaultValues({
        customisation: params?.customisation,
        name: params?.name
      });
      if (defResponse) {
        setDefaultData(defResponse);
        setAscending(defResponse.ascending ? 'Yes' : 'No');
        setLimit(defResponse.limit.toString());
        setGroup(defResponse.group);
        setOrder(defResponse.order);
        setDate({ from: defResponse.from, to: defResponse.to });
      }
    }
    fetchDefaultValues();
    setDashboardFirstRunState(false);
  }, []);

  useEffect(() => {
    if (!defaultData) return;

    const register = async () => {
      let customisationPayload = null;
      if (profile.role.value === 'Administrator' || profile.role.value === 'SuperUser') {
        if (customisation) {
          customisationPayload = customisation;
        }
      } else {
        customisationPayload = profile.customisation_id;
      }

      const metric = await registerMetric({
        name: chartName,
        date,
        group,
        order,
        ascending,
        limit,
        customisation: customisationPayload
      });
      if (metric) {
        setMetric(metric);
      }
    };

    register();
  }, [defaultData, limit, ascending, date]);

  // Set order after user stop typing for 1 second
  useEffect(() => {
    if (!showOrderSearch) return;
    const setDataDebounce = () => {
      setOrder(orderSearch);
    };

    const intervalId = setInterval(setDataDebounce, 1000); // Change the interval time as needed

    // Cleanup function to cancel previous API call
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [orderSearch]);

  // Change Chart
  useDidMountEffect(() => {
    async function fetchDefaultValues() {
      let customisationPayload = null;
      if (profile.role.value === 'Administrator' || profile.role.value === 'SuperUser') {
        if (customisation) {
          customisationPayload = customisation;
        }
      } else {
        customisationPayload = profile.customisation_id;
      }

      const [defResponse] = await getDefaultValues({
        customisation: customisationPayload,
        name: chartName
      });

      if (defResponse) {
        setDefaultData(defResponse);
        setAscending(defResponse.ascending ? 'Yes' : 'No');
        setLimit(defResponse.limit.toString());
        setGroup(defResponse.group);
        setOrder(defResponse.order);
        setDate({ from: defResponse.from, to: defResponse.to });
      }
    }

    fetchDefaultValues();

    if (customisation && chartName) {
      navigate(`/dashboard/${customisation}/${chartName}`);
    } else if (chartName) {
      navigate(`/dashboard/${chartName}`);
    }
  }, [customisation, chartName]);

  let chartDataToUse;

  if (isDirectURL) {
    chartDataToUse = chartData || [tableColumn];
  } else {
    chartDataToUse = chartData.length > 1 ? chartData : [[], []];
  }

  function findRecordByValueAndSeries(value) {
    if (!rawData || !rawData.length === 0) return;
    const record = rawData.find((item) => item.Name === value);
    if (!record) return;
    if (record?.Tag) {
      navigate(`/booking/view/${record.Tag}`);
    }
  }

  useEffect(() => {
    if (redirectValue) {
      findRecordByValueAndSeries(redirectValue);
    }
  }, [redirectValue]);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => {
        if (!dynamicChart?.customisation || !dynamicChart?.name) return;
        if (!isDirectURL) navigate(`/dashboard/${dynamicChart.customisation}/${dynamicChart.name}`);
      }}
      onClick={() => {
        if (!dynamicChart?.customisation || !dynamicChart?.name) return;
        if (!isDirectURL) navigate(`/dashboard/${dynamicChart.customisation}/${dynamicChart.name}`);
      }}
      className="border p-4 min-h-[80px]"
    >
      {chartModal && (
        <Link
          to="/dashboard"
          className="inline-flex text-paragraph-small text-primary-black hover:text-primary-red"
        >
          <BackIcon /> <span className="ml-3">Back</span>
        </Link>
      )}
      <div className={chartModal ? '' : 'hidden'}>
        <div
          className={`grid gap-x-4 items-center mt-8 ${
            profile.role.value === 'Administrator' || profile.role.value === 'SuperUser'
              ? 'grid-cols-[1fr_1fr_1fr_1fr_1fr_auto]'
              : 'grid-cols-[1fr_1fr_1fr_1fr_auto]'
          }`}
        >
          {(profile.role.value === 'Administrator' || profile.role.value === 'SuperUser') && (
            <DropdownCustomisaton
              field={{
                name: 'customisation',
                label: 'Customisation',
                required: false
              }}
              value={customisation}
              setFieldValue={(_, v) => setCustomisation(v)}
              metricPage
              errors={{}}
              touched={{}}
              loraLassan
            />
          )}
          <DropdownChartNames
            field={{
              name: 'chartName',
              label: 'Chart',
              required: false
            }}
            value={chartName}
            customisation={customisation}
            setFieldValue={(_, v) => setChartName(v)}
            metricPage
            errors={{}}
            touched={{}}
            isClearable={false}
          />
          <DateTimePicker
            field={{
              name: 'from',
              label: 'From',
              value: date.from,
              placeholder: 'DD/MM/YYYY',
              format: 'dddd DD/MM/yyyy HH:mm',
              required: true
            }}
            showTime="HH:mm"
            value={date.from}
            setFieldValue={setFromDate}
            pastDateAllowed
            stateCall
            touched={{}}
            errors={{}}
            // icon={<CalendarLogo />}
          />

          <DateTimePicker
            field={{
              name: 'to',
              label: 'To',
              value: date.to,
              placeholder: 'DD/MM/YYYY',
              format: 'dddd DD/MM/yyyy HH:mm',
              notAllowedDates: date.from,
              required: true
            }}
            showTime="HH:mm"
            value={date.to}
            setFieldValue={setToDate}
            pastDateAllowed={false}
            stateCall
            errors={{}}
            touched={{}}
            // icon={<CalendarLogo />}
          />
          <DropdownSelect
            field={{
              type: 'text',
              name: 'range',
              label: 'Date Range',
              helpText: '',
              id: 'range',
              required: false,
              options: [
                { name: 'Today', identity: 'Today' },
                { name: 'Yesterday', identity: 'Yesterday' },
                { name: 'This month', identity: 'This month' },
                { name: 'This year', identity: 'This year' },
                { name: 'Last 7 days', identity: 'Last 7 days' },
                { name: 'Last 30 days', identity: 'Last 30 days' },
                { name: 'Last 60 days', identity: 'Last 60 days' },
                { name: 'Last 90 days', identity: 'Last 90 days' },
                { name: 'Last month', identity: 'Last month' },
                { name: 'Last year', identity: 'Last year' }
              ]
            }}
            initialValue={range}
            setFieldValue={(_, v) => {
              setRange(v);
              setDatesRange(v);
            }}
            disableSearch
            isClearable={false}
            compact={false}
            touched={{}}
            errors={{}}
            onBlur={() => {}}
          />
          <PrimaryButton
            className="self-center mb-4 lg:mb-0 md:mt-[10px]"
            text="Reset"
            type="button"
            onClick={searchResetHandler}
          />
        </div>

        <div className="md:grid grid-cols-[1fr_1fr_1fr_1fr] gap-x-4 items-start">
          <div>
            <DropdownMetricGroup
              field={{
                type: 'text',
                name: 'group',
                label: 'Group',
                helpText: 'Group',
                value: group,
                id: 'spend_metric_group',
                required: false
              }}
              setFieldValue={(_, e) => {
                setGroup(e);
                const register = async () => {
                  let customisationPayload = null;
                  if (profile.role.value === 'Administrator' || profile.role.value === 'SuperUser') {
                    if (customisation) {
                      customisationPayload = customisation;
                    }
                  } else {
                    customisationPayload = profile.customisation_id;
                  }

                  const metric = await registerMetric({
                    name: chartName,
                    date,
                    group: e,
                    order,
                    ascending,
                    limit,
                    customisation: customisationPayload
                  });
                  if (metric) {
                    setMetric(metric);
                  }
                };
                register();
              }}
              chartName={chartName}
              value={group}
              touched={{}}
              errors={{}}
              onBlur={() => {}}
            />{' '}
          </div>

          <div>
            {/* {showOrderSearch ? (
              <SimpleInput
                field={{
                  name: 'order',
                  label: 'Order',
                  placeholder: '',
                  type: 'text',
                  required: false
                }}
                autoFocus={false}
                value={orderSearch}
                handleChange={(e) => setOrderSearch(e.target.value)}
                touched={{}}
                errors={{}}
              />
            ) : (
              <DropdownMetricOrder
                field={{
                  type: 'text',
                  name: 'order',
                  label: 'Order',
                  helpText: 'Order',
                  value: order,
                  id: 'spend_metric_order',
                  required: false
                }}
                value={order}
                setFieldValue={(_, e) => setOrder(e)}
                setShowOrderSearch={setShowOrderSearch}
                chartName={dynamicChart.name}
                touched={{}}
                errors={{}}
                onBlur={() => {}}
                helperType="spend"
                readOnly
              />
            )} */}
            <DropdownMetricOrder
              field={{
                type: 'text',
                name: 'order',
                label: 'Order',
                helpText: 'Order',
                value: order,
                id: 'spend_metric_order',
                required: false
              }}
              value={order}
              setFieldValue={(_, e) => {
                setOrder(e);
                const register = async () => {
                  let customisationPayload = null;
                  if (profile.role.value === 'Administrator' || profile.role.value === 'SuperUser') {
                    if (customisation) {
                      customisationPayload = customisation;
                    }
                  } else {
                    customisationPayload = profile.customisation_id;
                  }

                  const metric = await registerMetric({
                    name: chartName,
                    date,
                    group,
                    order: e,
                    ascending,
                    limit,
                    customisation: customisationPayload
                  });
                  if (metric) {
                    setMetric(metric);
                  }
                };
                register();
              }}
              setShowOrderSearch={setShowOrderSearch}
              chartName={chartName}
              touched={{}}
              errors={{}}
              onBlur={() => {}}
              readOnly={showOrderSearch}
            />
          </div>

          <DropdownSelect
            field={{
              type: 'text',
              name: 'limit',
              label: 'Limit',
              helpText: '',
              id: 'limit',
              required: false,
              options: [
                { name: '5', identity: '5' },
                { name: '10', identity: '10' },
                { name: '20', identity: '20' },
                { name: '50', identity: '50' },
                { name: '100', identity: '100' }
              ]
            }}
            initialValue={limit}
            setFieldValue={(_, v) => setLimit(v)}
            isClearable={false}
            compact={false}
            touched={{}}
            errors={{}}
            onBlur={() => {}}
          />

          {/* <div className="custom-dropdown-style custom-border">
              <span className="mb-1 inline-block">Limit</span>
              <Dropdown
                options={[5, 10, 15, 20]}
                onChange={(e) => setLimit(e.value)}
                value={{
                  value: limit,
                  label: limit
                }}
                className="!w-full"
                placeholder="Select an option"
              />
            </div> */}

          <DropdownSelect
            field={{
              type: 'text',
              name: 'ascending',
              label: 'Ascending',
              helpText: '',
              id: 'ascending',
              required: false,
              options: [
                { name: 'Yes', identity: 'Yes' },
                { name: 'No', identity: 'No' }
              ]
            }}
            initialValue={ascending}
            setFieldValue={(_, v) => setAscending(v)}
            compact={false}
            isClearable={false}
            touched={{}}
            errors={{}}
            onBlur={() => {}}
          />

          {/* <div className="custom-dropdown-style custom-border">
              <span className="mb-1 inline-block">Ascending</span>
              <Dropdown
                options={['True', 'False']}
                onChange={(e) => setAscending(e.value)}
                value={{
                  value: ascending ? 'True' : 'False',
                  label: ascending ? 'True' : 'False'
                }}
                className="!w-full"
                placeholder="Select an option"
              />
            </div> */}
        </div>
      </div>

      <div className="relative">
        {(isLoading || isRefetching) && (
          <div className="inset-0 absolute z-10 rounded grid place-items-center">
            <span className="inset-0 absolute z-10 bg-black/10 rounded" />
            <span className="loader" />
          </div>
        )}
        {chartData && chartData.length > 1 ? (
          <Chart
            chartType="BarChart"
            data={chartDataToUse}
            options={{
              ...options,
              title: data?.chartTitle || '',
              hAxis: {
                format: data?.chartType === 'Currency' ? '£#,##0.00' : ''
              }
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback: ({ chartWrapper }) => {
                  const chart = chartWrapper.getChart();
                  const selection = chart.getSelection();
                  if (selection.length > 0) {
                    const [selectedItem] = selection;
                    const value = chartWrapper.getDataTable().getValue(selectedItem.row, 0);
                    if (value) {
                      setRedirectValue(value);
                    }
                  }
                }
              }
            ]}
          />
        ) : (
          <p>
            <center>No chart data available for the selected period</center>
          </p>
        )}
      </div>
      {/* {chartModal && (
        <button
          type="button"
          onClick={() => setChartModal(false)}
          className="absolute top-4 right-4 bg-primary-red rounded-full w-10 h-10 text-center text-white font-bold z-50"
        >
          X
        </button>
      )} */}
    </div>
  );
}

SpendingChart.propTypes = {
  dynamicChartProp: PropTypes.object
};
